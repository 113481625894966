<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Blog Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Blog Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="blog-details-item">
                    <div class="blog-details-img">
                        <img  [src]="blogsData?.cover_img" alt="Blog Details" style="width:68% ; margin-left: 172px;" >
                        <h2>{{blogsData?.title}}</h2>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>{{blogsData?.short_desc}}</p>
                        <!-- <p>Combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always.</p> -->
                        <p>{{blogsData?.long_desc}}</p>
                    </div>
                    
                    <div class="blog-details-social">
                        <ul>
                            <li><span>Share on:</span></li>
                            <li><a target="_blank" href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a target="_blank" href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a target="_blank" href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>

                    <!-- <div class="blog-details-nav">
                        <div class="nav-prev">
                            <a routerLink="/blog-details">Previous</a>
                        </div>

                        <div class="nav-next">
                            <a routerLink="/blog-details">Next</a>
                        </div>
                    </div> -->

                    <!-- <div class="blog-details-contact">
                        <h2>Drop Your Comment</h2>

                        <div class="contact-form">
                            <form>
                                <div class="contact-wrap">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Your Full Name">
                                    </div>
            
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                                    </div>
            
                                    <div class="form-group">
                                        <select class="form-control wide">
                                            <option>Case Category</option>
                                            <option>Another option</option>
                                            <option>A option</option>
                                            <option>Potato</option>
                                        </select>	
                                    </div>
            
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required  placeholder="Case Description"></textarea>
                                    </div>
            
                                    <div class="text-start">
                                        <button type="submit" class="contact-btn">Post A Comment</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>
<!-- 
            <div class="col-lg-5">
                <div class="blog-details-item">
                    <div class="blog-details-category">
                        <h3>Category</h3>

                        <ul>
                            <li><a routerLink="/practice-details">Criminal Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Civil Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Education Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Family Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Cyber Law <i class="icofont-arrow-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="blog-details-search">
                        <div class="search-area">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit" class="btn blog-details-btn">
                                <i class="icofont-search-2"></i>
                            </button>
                        </div>

                        <h3>Recent Blogs</h3>

                        <ul>
                            <li>
                                <img src="assets/img/blog-details/2.jpg" alt="Blog Details">

                                <div class="blog-details-recent">
                                    <h4><a routerLink="/blog-details">There are many variations of passages.</a></h4>

                                    <ul>
                                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Admin</a></li>
                                        <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                                    </ul>
                                </div>
                            </li>

                            <li>
                                <img src="assets/img/blog-details/3.jpg" alt="Blog Details">

                                <div class="blog-details-recent">
                                    <h4><a routerLink="/blog-details">Justice May For You If You Are Innocent child</a></h4>

                                    <ul>
                                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Admin</a></li>
                                        <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                                    </ul>
                                </div>
                            </li>

                            <li>
                                <img src="assets/img/blog-details/4.jpg" alt="Blog Details">

                                <div class="blog-details-recent">
                                    <h4><a routerLink="/blog-details">Who Can the Victim a Sue after a Car Accident?</a></h4>

                                    <ul>
                                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">Admin</a></li>
                                        <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="blog-details-tags">
                        <h3>Tags</h3>

                        <ul>
                            <li><a routerLink="/blog">Government</a></li>
                            <li><a routerLink="/blog">Humanities</a></li>
                            <li><a routerLink="/blog">Justice</a></li>
                            <li><a routerLink="/blog">Social</a></li>
                            <li><a routerLink="/blog">Virtue</a></li>
                            <li><a routerLink="/blog">Rape</a></li>
                            <li><a routerLink="/blog">Criminal</a></li>
                            <li><a routerLink="/blog">Political</a></li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<!-- <section class="blog-area blog-area-three">
    <div class="container">
        <div class="section-title text-start">
            <h2>Related Blogs</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Rights Case</span>
                        <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Business Case</span>
                        <h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <a routerLink="/blog-details">
                        <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                    </a>

                    <div class="blog-inner">
                        <span>Criminal Case</span>
                        <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                        <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->