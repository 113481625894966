<footer>
    <!-- <div class="newsletter-area">
        <div class="container">
            <img src="assets/img/home-one/newsletter.png" alt="Shape">
            <h2>Subscribe to appetite</h2>

            <form class="newsletter-form">
                <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" required autocomplete="off">
                <button class="btn contact-btn" type="submit">Subscribe</button>
            </form>
        </div>
    </div> -->

    <div class="container  pt-5">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                     <a routerLink="/"><img src="assets/img/Vrish Logo.png" alt="Logo" height="80px" width="100px"></a>
                        <p><span class="gold-font">VRISH</span>, as an Indian cuisine restaurant offers an authentic taste of India in a vibrant and contemporary setting.</p>
                        <ul>
                            <!-- <li><a href="https://www.youtube.com/" target="_blank"><i class="icofont-youtube-play"></i></a></li> -->
                            <li><a href="https://www.facebook.com/share/hwvdEKtdYSs4Vufk/?mibextid=LQQJ4d" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/vrish_indian_restaurant/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <!-- <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Services</h3>

                        <ul>
                            <li><a href="http://vrish.bayforall.co.uk/" target="_blank"><i class="icofont-simple-right"></i>Order Online</a></li>
                            <li><a routerLink="/reservation"><i class="icofont-simple-right"></i>Reserve a table </a></li>
                            <!-- <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Civil Law</a></li>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Criminal Law</a></li>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Business Law</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/home"><i class="icofont-simple-right"></i> Home</a></li>
                            <li><a routerLink="/about"><i class="icofont-simple-right"></i> About</a></li>
<!--                            <li><a routerLink="/gallery"><i class="icofont-simple-right"></i> Gallery</a></li>-->
<!--                            <li><a routerLink="/case-study"><i class="icofont-simple-right"></i> Menu</a></li>-->
                            <li><a routerLink="/blog"><i class="icofont-simple-right"></i> Blogs</a></li>
<!--                            <li><a routerLink="/faq"><i class="icofont-simple-right"></i> Faq</a></li>-->
                            <li><a routerLink="contact"><i class="icofont-simple-right"></i> Contact</a></li>
                            <li><a routerLink="/privacy-policy"><i class="icofont-simple-right"></i> Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions"><i class="icofont-simple-right"></i> Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Find Us</h3>

                        <ul>
                            <li><i class="icofont-location-pin"></i> 115 London Rd, South Benfleet, Benfleet SS7 5UH, United Kingdom</li>
                            <li><i class="icofont-ui-call"></i><a href="tel:+44 7440020888"> +44 7440 020 888</a></li>
                            <li><i class="icofont-at" ></i> <a href="mailto:support@vrish.co.uk" >support@vrish.co.uk</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-sm-7 col-lg-6">
                    <div class="copyright-item">
                        <p>@ 2024 by Vrish. All rights reserved.</p>
                    </div>
                </div>

                <div class="col-sm-5 col-lg-6">
                    <div class="copyright-item copyright-right">
                        <a routerLink="/terms-conditions">Terms & Conditions</a> <span>-</span>
                        <a routerLink="/privacy-policy">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
