<div class="page-title-area page-title-area-three title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>The secrets of our culinary magic.</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>About Us</li>
                </ul>

                <div class="page-title-btn">
                    <!-- <a routerLink="/testimonial">Visual Delights<i class="icofont-arrow-right"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="help-area help-area-two help-area-four pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/5.jpg" alt="Help">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2 class="gold-font">Experience food like never before!</h2>
                        <p>Our VRISH was formed on the conviction that the rich flavours and spices of Indian food should be shared and cherished with the rest of the world.</p>
                        <p>Our journey began with a vision to introduce authentic Indian food to the UK, utilising the freshest ingredients and traditional cooking techniques to provide our diners with delicious and unforgettable dining experiences.
                            From carefully preparing our delicacies to offering courteous and quick service, our team is dedicated to surpassing your expectations.</p>
                        <!-- <div class="help-inner-left">
                            <ul>
                                <li>
                                    <i class="icofont-web"></i> Browse Our Website</li>
                                <li><i class="flaticon-checkmark"></i> Choose Services</li>
                            </ul>
                        </div>
                        <div class="help-inner-right">
                            <ul>
                                <li><i class="icofont-android-nexus"></i> Quick Reply</li>
                                <li><i class="icofont-dart"></i> Best Performances</li>
                            </ul>
                        </div> -->

                        <!-- <div class="help-signature">
                            <img src="assets/img/home-one/4.png" alt="Signature">
                        </div> -->

<!--                        <h3 class="mt-5 my-10">Sai Prasad</h3>-->
<!--                        <span>Founder and CEO</span>-->
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center help-wrap">
            <div class="col-lg-6 " >
                <div class="help-item">
                    <div class="help-right">
                        <h2 class="gold-font">The Ethos for our preparation.</h2>
                        <p>Ultimately, VRISH uses high-quality ingredients, follows traditional cooking techniques, and pays attention to detail in all aspects of food preparation and presentation.  We do believe that using fresh, high-quality ingredients is essential for any restaurant because it not only enhances the taste and quality of the food but also reflects the restaurant's commitment to excellence.</p>

                        <!-- <div class="help-inner-left">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> On Time Response</li>
                                <li><i class="flaticon-checkmark"></i> Legal Way</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Legal Services</li>
                                <li><i class="flaticon-checkmark"></i> Honest Work</li>
                            </ul>
                        </div> -->

                        <!-- <a class="cmn-btn" routerLink="/practice">Learn More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/6.jpg" alt="Help">
                </div>
            </div>
        </div>

        <div class="help-shape">
            <img src="assets/img/home-one/6.png" alt="Shape">
        </div>
    </div>
</div>

<section class="expertise-area expertise-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>OUR EXPERTISE</span>
            <h2>Bringing passion to the plate</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Attention to Detail</h3>
                            <p> Through our chefs’ attention to every detail, we are able to serve you the high quality dishes that are visually appealing, delicious and healthy.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="icofont-company"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Passion</h3>
                            <p>It is through their passion, our chefs are constantly improving their skills to create exceptional dishes that leave our diners with satisfaction and an eager to return.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="icofont-handshake-deal"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Communication skills</h3>
                            <p> Our chefs’ work together to create outstanding meals through effective communication among their team to ensure that everyone is on the same page.</p>
                        </li>
                    </ul>
               </div>
            </div>

            <div class="col-lg-6">
               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-money-bag"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Time management</h3>
                            <p>In our busy kitchen, our chefs always keep a track on their order to ensure that each dish is prepared and delivered on time.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-time"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Creativity</h3>
                            <p>Our culinary artists are more likely to experiment with new ingredients and cooking techniques, which can result in unique and delicious dishes.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-conversation"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Adaptability</h3>
                            <p> Agile chefs are able to adapt to the pace and intensity of the work, making quick decisions and adjusting their approach as needed.</p>
                        </li>
                    </ul>
               </div>
            </div>
        </div>
    </div>
</section>

<div class="about-area pt-100">
    <div class="container">
        <div class="row">
            <div class="about-item">
                <div class="about-information">
            <div class="col-md-12 ">
            <h2 style="text-align: center; color: rgb(243, 208, 9);">About the Indian cuisine</h2>
            </div>
                </div>
            </div>
            <!-- <div class="col-lg-12 my-3">
                <div class="about-item">
                    <!-- <div class="about-video-wrap my-5">
                        <div class="about-video">
                            <img src="assets/img/about/2.jpg" alt="About">
                        </div>

                        <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                    </div> -->

                    <!-- <div class="about-content">
                        <h2 style="color: #daa938;">Our skills makes us famous</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </div> -->
                <!-- </div>
            </div> --> 

            <div class="col-lg-12 my-2" >
                <div class="about-item">
                    <div class="about-information">
                    
             <p>Indian cuisine is a diverse and flavorful cuisine that has been influenced by various cultures and regions.
               One of the key elements of Indian cuisine is the use of spices, which are used in varying combinations to create different flavor profiles.
            <br> Common spices used in Indian cuisine include cumin, coriander, turmeric, ginger, garlic, cardamom, cinnamon, and chili peppers.
             Some of the most popular dishes in Indian cuisine include chicken tikka masala, butter chicken, biryani, parathas and the list can really be a long one. In addition to savory dishes,
                Indian cuisine also features a range of sweet dishes, such as kulfi, rasgulla, and gulab jamun.<br>
              Overall, Indian cuisine is a rich and flavorful cuisine that has a diverse range of dishes and flavors to offer. It is enjoyed by people all over the world and has become a popular cuisine in many countries.</p>
                </div>
             <!-- <div class="about-information">

                        <h2><span>21 feb 1998,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div> -->

                    <!-- <div class="about-information">
                        <h2><span>10 March 2000,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div> -->

                    <!-- <div class="about-information">
                        <h2><span>9 Nov 2000,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>25 Jan 2010,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
