import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import * as blogsData from '../../../../assets/json/blogs-details.json';

interface Blogs {
  id: Number;
  title: String;
  cover_img: String;
  short_desc: String;
  long_desc: String;
  date: String;
  author_name: any;
}

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  blogsList: Blogs[] = blogsData;
  blogsData : any='';
  webhost: any;

  constructor(public sanitizer: DomSanitizer) {
  
   }

  ngOnInit(): void {
    this.blogsData = JSON.parse(localStorage.getItem('blogsDetails'));
    console.log(this.blogsData,'blog');
    fetch('../../../../assets/json/blogs-details.json').then(res => res.json())
        .then(jsonData => {
          this.blogsList = jsonData;
          this.blogsList.length = 5;
          console.log(this.blogsList ,'stueents');
        });

  }
  blogsDetailsPage(data){
    localStorage.setItem('blogsDetails', JSON.stringify(data));
    this.blogsData = JSON.parse(localStorage.getItem('blogsDetails'));
  }

}
