import {Component, Inject, OnInit,  AfterViewInit, ElementRef} from '@angular/core';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import { DOCUMENT } from '@angular/common';
interface Time {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-reservation',
    templateUrl: './reservation.component.html',
    styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit , AfterViewInit{
    show:boolean = false;

    showReservation =  false;

    public reservationForm: FormGroup;



    times: Time[] = [
        {value: 'Time', viewValue: '1PM'},
        {value: 'Time1', viewValue: '2PM'},
        {value: 'Time1', viewValue: '3PM'},
        {value: 'Time1', viewValue: '4PM'},
        {value: 'Time1', viewValue: '5PM'},
    ];

    constructor(private fb: FormBuilder) {

    }

    ngOnInit(): void {
        this.reservationForm = this.fb.group({
            'date': ['', Validators.required],
            'timeValue': ['', Validators.required],
            'guest': ['', Validators.required],
            'fullname': ['', Validators.required],
            'email': ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            'phonenumber': ['', [Validators.required,
                Validators.pattern('^[0-9]*$'),
                Validators.minLength(10), Validators.maxLength(10)]],
            'address': ['', Validators.required],
        });
    }

    ngAfterViewInit() {
        this.showReservation = true;
        this.addScript();

      }

      addScript() {
        let script = document.createElement('script');
        script.setAttribute('src', 'https://booking-widget.quandoo.com/index.js');
        script.setAttribute('data-merchant-id' , '99270');
        script.setAttribute('data-theme' , 'dark');
        script.setAttribute('data-primary-color', 'd0a549');
        document.body.appendChild(script);
      }



    reservationButton() {
      console.log(this.reservationForm , 'form');
        let payload: any = {
            date: this.reservationForm.get('date')?.value,
            time: this.reservationForm.get('timeValue')?.value,
            guest: this.reservationForm.get('guest')?.value,
            fullname: this.reservationForm.get('fullname')?.value,
            email: this.reservationForm.get('email')?.value,
            phonenumber: this.reservationForm.get('phonenumber')?.value,
            address: this.reservationForm.get('address')?.value,
        };
        console.log(payload);
    }

    getSelectedValue(value: any) {
        console.log(value);
    }
}



