<div class="navbar-area fixed-top">

    <div class="mobile-nav">
        <a routerLink="/" class="logo">
          <img src="assets/img/Vrish Logo.png" alt="Logo" style="max-width:69% !important">
        </a>
    </div>

    <div class="main-nav">
        <!-- <nav class="navbar navbar-expand-xl navbar-light cookie-consent" *ngIf="show" >  
            <span>We are ready to unveil the flavours of India, from today! YES we are open</span>
       </nav> -->
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                  <img src="assets/img/Vrish Logo.png" alt="Logo" height="80px" width="100px">
                </a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <!-- <a href="javascript:void(0)" class="nav-link dropdown-toggle">Home</a> -->
                            <a routerLink="/home" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>

                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 2</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 3</a></li>
                            </ul> -->
                        </li>


                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Attorneys</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/attorney" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Attorney</a></li>

                                <li class="nav-item"><a routerLink="/attorney-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Attorney Details</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="/menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Menu</a></li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Case Studies</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Study</a></li>

                                <li class="nav-item"><a routerLink="/case-study-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Study Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blogs</a></li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blogs</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->

<!--                        <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Faq</a></li>-->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/practice" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Practice</a></li>

                                <li class="nav-item"><a routerLink="/practice-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Practice Details</a></li>

                                <li class="nav-item"><a routerLink="/appointment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Appointment</a></li>

                                <li class="nav-item"><a routerLink="/testimonial" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonial</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        <li class="nav-item"><a routerLink="blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>


                        <!-- <li class="nav-item">
                            <a routerLink="/christmas" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                            <span class="position-relative" style="    text-decoration: underline;
    color: #daa938;">
                                Christmas feast
                                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    New
    <span class="visually-hidden">unread messages</span>
  </span>
                            </span>
                        </a></li> -->
                    </ul>
<!--                    <div  class="mx-2 nav-word">-->
<!--                        <a  class="nav-link" routerlink="/"  href="/"  routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" style="color: white;">-->
<!--                           Sign in-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div  class="mx-2 nav-cart">-->
<!--                        <a  routerlink="/"  href="/">-->
<!--                            <i class="icofont-cart"></i>-->
<!--                        </a>-->
<!--                    </div>-->

                    <div *ngIf="!getDevice()">
                        <a style="
    color: #ffffff !important;
    border: 1px solid #daa938;
    padding: 18px 25px;" href="http://vrish.bayforall.co.uk/" target="_blank">Order Online</a>
                    </div>
                    <div *ngIf="!getDevice()" class="ms-3">
                        <a style="
    color: #ffffff !important;
    border: 1px solid #daa938;
    padding: 18px 25px;" routerLink="/reservation">Reservation</a>
                    </div>

                </div>

            </nav>
        </div>
    </div>
</div>
