import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    title(title: any) {
      throw new Error('Method not implemented.');
    }
    location: any;
    routerSubscription: any;
    show:boolean = true;

    constructor(private router: Router,private cookie:CookieService) {

        console.log(this.cookie.get("userRole"));
        if (this.cookie.get("userRole") == 'admin'){
           this.show = false;
        }else if(this.cookie.get("userRole") == 'cancelled'){
            this.show = false;
        }else{
           this.show = true;
        }
    }

    ngOnInit(){
        this.recallJsFuntions();
        $('#myModal').modal('show');
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.loader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/custom.js');
            $('.loader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
    setCookie(){
        this.cookie.set("userRole","admin");
        // alert("UserRole with ("+ this.cookie.get("userRole")+")Sucessfully loged");
        this.show=false;
    }
    removeAll(){
        this.cookie.set("userRole","cancelled");
        // this.cookie.deleteAll();
        this.show=false;
    }
    
}
