import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators,FormBuilder,ReactiveFormsModule, } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { urls } from 'src/app/services/urls';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public contactForm:FormGroup;
  
  

  constructor(private fb: FormBuilder,private auth: AuthService,private toastr: ToastrService) {

   }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      "name" : ['', Validators.required],
      "email": ['', Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      "mobile" : ['',   Validators.required,Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)],
      "subject" : ['', Validators.required],
      "description" : ['', Validators.required],
    });
  }
  contactButton(){
    const url = 'https://vrish.co.uk/vrish-backend/api/index.php/' +urls.sendQuery;
    let payload: any = {
        platform: 'web',
          "name":this.contactForm.get('name')?.value,
          "email": this.contactForm.get('email')?.value,
          "mobile": this.contactForm.get('mobile')?.value,
          "subject": this.contactForm.get('subject')?.value,
          "description": this.contactForm.get('description')?.value
    }
    this.auth.postService(payload, url).subscribe(
      (successData: any) => {
        if (successData.IsSuccess) {
          this.toastr.success(successData.ResponseObject);
          this.contactForm.reset();
        } else {
          this.toastr.error(successData.ErrorObject);
        }
      },
      (err) => {
        console.log(err, 'error in CateGoryList');
      }
    )
  }
 


}
