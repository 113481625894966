<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Privacy Policy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <h2>About this Policy</h2>
        <p>VRISH Restaurant is committed to protecting your privacy and ensuring that your personal information is
            handled in a safe and responsible way. This policy outlines how we aim to achieve this and includes the
            information collected when:</p>
        <ul>
            <li>you use our website <a href="https://www.vrish.co.uk" target="_blank">www.vrish.co.uk</a>.</li>
            <li>you make a booking on our website.</li>
            <li>you place an order on our website.</li>
            <li>you make enquiries through our website.</li>
        </ul>

        <h3>Definition of Personal Data</h3>
        <p>Personal Data refers to any data that relates to an identifiable person who can be directly or indirectly
            identified from that data. In this case, it means personal data that you provide to us via our website. By
            submitting your personal data, you agree to its use in accordance with this policy. Please take the time to
            carefully read and fully understand this policy.</p>

        <h3>Who Are We?</h3>
        <p>VRISH Restaurant is a venue located at:</p>
        <address>
            115 London Rd,<br>
            South Benfleet, Benfleet<br>
            SS7 5UH, United Kingdom
        </address>
        <p>For customer support, you can contact us at:</p>
        <ul>
            <li>Phone: +44 7440 020 888</li>
            <li>Email: <a href="mailto:support@vrish.co.uk">support@vrish.co.uk</a></li>
        </ul>

        <h3>How Do We Collect Information from You?</h3>
        <p>We collect information from you in the following ways:</p>
        <ul>
            <li>When you make an order.</li>
            <li>When you make a booking.</li>
            <li>When you create a user account.</li>
            <li>When you contact us via our contact form.</li>
        </ul>

        <h3>What Type of Information Is Collected from You?</h3>
        <p>You may be asked to submit personal information when making a booking or using our services. This information
            is necessary for fulfilling your request and providing the best service possible. The information we collect
            includes:</p>

        <h4>When You Make a Booking:</h4>
        <ul>
            <li>Title</li>
            <li>Name</li>
            <li>Email address (used for booking confirmations and feedback)</li>
            <li>Home or work address</li>
            <li>Billing information</li>
            <li>Telephone number</li>
        </ul>

        <h4>When You Access Our Website:</h4>
        <p>We may automatically collect "Device Information" about your computer or device, which can include:</p>
        <ul>
            <li>Device type (e.g., mobile, computer, tablet)</li>
            <li>Cookies</li>
            <li>Operating system</li>
            <li>IP address</li>
            <li>Browser type and version</li>
            <li>Browser language and history</li>
            <li>Domain names</li>
            <li>Access times</li>
            <li>Referring website addresses</li>
            <li>Other device-specific information necessary for providing services</li>
        </ul>

        <h4>Location Information:</h4>
        <p>If you use our website, we may receive generic location data (such as your city or neighborhood).</p>

        <h3>How Is Your Information Used?</h3>
        <p>We use your personal data for various legitimate business purposes, such as:</p>
        <ul>
            <li>Internal record keeping.</li>
            <li>Sending you service-related emails (e.g., booking confirmations and post-dining feedback).</li>
            <li>Improving our products and services.</li>
            <li>Sending marketing communications, if you have opted in to receive them.</li>
            <li>Customizing the website experience based on your interests.</li>
        </ul>
        <p>We ensure that our use of your personal data has a lawful basis, either because it is necessary to fulfill a
            booking or transaction, because you have consented (e.g., subscribing to emails), or because it is in our
            legitimate interests.</p>

        <h3>Who Has Access to Your Information?</h3>
        <p>We do not sell, distribute, or lease your personal information to third parties. We will only share your
            information with trusted companies that help us deliver services on our behalf. These third parties may
            include:</p>
        <ul>
            <li>Delivery service providers</li>
            <li>Third-party payment processors</li>
            <li>Other partners who assist us in providing services and fulfilling your requests</li>
        </ul>
        <p>Third parties whose content appears on our site (such as social media links, advertisements, etc.) may use
            third-party cookies. We do not control how these third parties use your data, and we encourage you to review
            their privacy policies.</p>

        <h3>Security</h3>
        <p>Data security is a top priority for us. We have implemented appropriate measures to safeguard and protect the
            data we collect through our website.</p>

        <h3>Use of Cookies</h3>
        <p>Like many other websites, we use cookies to improve your browsing experience. A cookie is a small text file
            placed on your device that helps our website recognize you on return visits. Cookies help us:</p>
        <ul>
            <li>Authenticate users, personalize content, and improve security.</li>
            <li>Analyze performance, such as tracking page views and conversion rates.</li>
            <li>Facilitate third-party services (e.g., Google Analytics) to assist in performance analysis.</li>
        </ul>
        <p>You can set your browser to refuse cookies, but this may limit certain website features. Please refer to our
            Cookie Policy for more information.</p>

        <h3>What Happens If Our Business Changes Hands?</h3>
        <p>If VRISH Restaurant is sold or undergoes a transfer of ownership, your personal data may be transferred along
            with the business. The new owner or controlling entity will be permitted to use your data only for the
            purposes it was originally collected for. You will be informed of any such changes in advance and given the
            option to have your data deleted or withheld from the new owner.</p>

        <h3>Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time to reflect feedback and changes in our business. We
            encourage you to review this policy periodically to stay informed about how we protect your information. The
            latest update was in <strong>November 2024</strong>.</p>

        <h3>Contact Information</h3>
        <p>If you have any questions or comments about this Privacy Policy or believe that we have not adhered to it,
            please contact us at:</p>
        <ul>
            <li>Phone: +44 7440 020 888</li>
            <li>Email: <a href="mailto:support@vrish.co.uk">support@vrish.co.uk</a></li>
        </ul>
        <p>We will make commercially reasonable efforts to address and resolve any concerns you may have.</p>
    </div>
</section>