import {Component, OnInit} from '@angular/core';
import {BeforeSlideDetail} from 'lightgallery/lg-events';
import lgZoom from 'lightgallery/plugins/zoom';
import {LightGallery} from 'lightgallery/lightgallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  private lightGallery!: LightGallery;
  private needRefresh = false;

  title = 'angular-demo';

  selectedImg = '';
    settings = {
    counter: false,
    plugins: [lgZoom]
  };
  items = [
    {
      id: '1',
      size: '1400-933',
      src:
          'assets/img/gallery/SAI00205.jpg',
      thumb:
          'assets/img/gallery/SAI00034.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@dann">Dan</a></h4>
            <p>Published on November 13, 2018</p>
        </div>`
    },
    {
      id: '2',
      size: '1400-933',
      src:
          'assets/img/gallery/SAI00046.jpg',
      thumb:
          'assets/img/gallery/SAI00052.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@kylepyt">Kyle Peyton</a></h4>
            <p>Published on September 14, 2016</p>
        </div>`
    },
    {
      id: '3',
      size: '1400-932',
      src:
          'assets/img/gallery/SAI00105.jpg',
      thumb:
          'assets/img/gallery/SAI00110.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
            <p>Published on May 8, 2020</p>
        </div>`
    },
    // {
    //   id: '3',
    //   size: '1400-932',
    //   src:
    //       'assets/img/blog-details/Indian-Cauliflower-Potatoe.jpg',
    //   thumb:
    //       'https://images.unsplash.com/photo-1588953936179-d2a4734c5490?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=240&q=80',
    //   subHtml: `<div class="lightGallery-captions">
    //         <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
    //         <p>Published on May 8, 2020</p>
    //     </div>
    // },
    {
      id: '4',
      size: '1400-932',
      src:
          'assets/img/gallery/SAI00123.jpg',
      thumb:
          'assets/img/gallery/SAI00123.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
            <p>Published on May 8, 2020</p>
        </div>`
    },
    {
      id: '5',
      size: '1400-932',
      src:
          'assets/img/gallery/SAI00148.jpg',
      thumb:
          'assets/img/gallery/SAI00148.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
            <p>Published on May 8, 2020</p>
        </div>`
    },
    {
      id: '6',
      size: '1400-932',
      src:
          'assets/img/gallery/SAI00219.jpg',
      thumb:
          'assets/img/gallery/SAI00219.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
            <p>Published on May 8, 2020</p>
        </div>`
    },
    {
      id: '7',
      size: '1400-932',
      src:
          'assets/img/gallery/SAI00277.jpg',
      thumb:
          'assets/img/gallery/SAI00277.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
            <p>Published on May 8, 2020</p>
        </div>`
    },
    {
      id: '8',
      size: '1400-932',
      src:
          'assets/img/gallery/SAI09840.jpg',
      thumb:
          'assets/img/gallery/SAI09840.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
            <p>Published on May 8, 2020</p>
        </div>`
    },
    {
      id: '9',
      size: '1400-932',
      src:
          'assets/img/gallery/SAI09906.jpg',
      thumb:
          'assets/img/gallery/SAI09906.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
            <p>Published on May 8, 2020</p>
        </div>`
    },
    {
      id: '10',
      size: '1400-932',
      src:
          'assets/img/gallery/SAI09927.jpg',
      thumb:
          'assets/img/gallery/SAI09927.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
            <p>Published on May 8, 2020</p>
        </div>`
    },
    {
      id: '11',
      size: '1400-932',
      src:
          'assets/img/gallery/SAI09955.jpg',
      thumb:
          'assets/img/gallery/SAI09955.jpg',
      subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
            <p>Published on May 8, 2020</p>
        </div>`
    },
    {
        id: '12',
        size: '1400-932',
        src:
            'assets/img/gallery/SAI09943.jpg',
        thumb:
            'assets/img/gallery/SAI09943.jpg',
        subHtml: `<div class="lightGallery-captions">
              <h4>Photo by <a href="https://unsplash.com/@jxnsartstudio">Garrett Jackson</a></h4>
              <p>Published on May 8, 2020</p>
          </div>`
      }
  ];
  
  onInit = (detail): void => {
    this.lightGallery = detail.instance;
  };
  addImage = () => {
    this.items = [
      ...this.items,
      {
        id: '4',
        size: '1400-933',
        src:
            'https://images.unsplash.com/photo-1609902726285-00668009f004?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1400&q=80',
        thumb:
            'https://images.unsplash.com/photo-1609902726285-00668009f004?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=240&q=80',
        subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@bruno_adam">Bruno Adam</a></h4>
            <p>Published on January 6, 2021</p>
        </div>`
      },
      {
        id: '2',
        size: '1400-933',
        src:
            'assets/img/blog-details/chickenbiriyani.jpg',
        thumb:
            'assets/img/blog-details/chickenbiriyani.jpg',
        subHtml: `<div class="lightGallery-captions">
            <h4>Photo by <a href="https://unsplash.com/@kylepyt">Kyle Peyton</a></h4>
            <p>Published on September 14, 2016</p>
        </div>`
      },
    ];
    this.needRefresh = true;
  };

  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };

  constructor(){
    this.selectedImg = this.items[0].src;
  }

  ngOnInit(): void {

  }
  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }


}
