<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Terms & Conditions</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Terms & Conditions</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <h2>Privacy Statement</h2>
            <p> We respect your privacy and are committed to protecting your personal data. Any information collected
                during your visit will be kept strictly confidential and will not be shared with third parties without
                your explicit consent. If your personal information is stored in our database, and you wish to have it
                removed or updated, please submit your request, and we will make the necessary changes within 72 hours.
            </p>
        </div>
        <div class="privacy-item">
            <h2>Payments</h2>
            <p>Our delivery drivers carry a maximum of £10 in change, and all payments must be made in £ sterling.</p>
            <br>
            <p>We accept the following payment methods:</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Credit / Charge Cards: Visa and Mastercard</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Debit Cards: Delta, Switch, and similar cards</p>
            <br>
            <p>By submitting your order, you are making an offer to purchase the selected items. We reserve the right to
                refuse any order, if necessary. In the event of a refund, we aim to process the credit to your account
                within 7-10 business days.</p>
        </div>

        <div class="privacy-item">
            <h2>Order Modifications & Cancellations</h2>
            <p>Your Order Confirmation represents our agreement to deliver the products you have purchased in full. If
                someone else has placed the order on your behalf, they are considered your agent. You may make
                amendments to your order or delivery time up to 45 minutes before your scheduled delivery window. All
                changes must be made by calling the shop directly.</p>
            <p>In the case of modifications to the order, a new payment will be processed, and the original payment will
                be cancelled. Delivery time slots may also be adjusted, provided an alternative time is available within
                45 minutes of the original delivery window.</p>
            <p>Orders can be fully cancelled up to 45 minutes before the scheduled delivery time. We will aim to issue
                any applicable refunds to your credit card within 7-10 business days. Cancellations made within 45
                minutes of the delivery window will be charged in full.</p>
        </div>

        <div class="privacy-item">
            <h2>Products</h2>
            <p>Side orders are subject to availability, and if an item is out of stock, our staff will offer suitable
                alternatives. Please note that while we take great care to avoid cross-contamination, some of our
                products may contain nuts or nut derivatives. Desserts and ice creams may contain traces of nuts.</p>
            <p>Although we do our best to remove all bones from chicken and meat products, there may still be occasional
                bone fragments. A minimum order value applies, and you will be notified if your order does not meet the
                minimum threshold during checkout.</p>
        </div>

        <div class="privacy-item">
            <h2>Delivery Times</h2>
            <p>We strive to provide the best possible food delivery service, ensuring that your order arrives within the
                quoted time frame. However, please understand that factors such as weather, traffic, or staff illness
                may occasionally affect delivery times. We appreciate your patience and understanding in such cases.</p>
        </div>

        <div class="privacy-item">
            <h2>Latest Order Time</h2>
            <p>In accordance with licensing laws, we cannot accept orders for delivery or collection less than 20
                minutes before our stated closing time. For customers collecting orders, we kindly ask that you arrive
                before the store closes to ensure we can serve you. If you have any questions, please contact our shop
                directly and speak with the duty manager.</p>
        </div>
    </div>

</section>