<div class="page-title-area page-title-area-two title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>We are here to serve, in every possible way! </h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Contact us</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="contact-form contact-form-four pb-100">
    <div class="loaction-area">
        <div class="container">
            <div class="row location-bg">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-pin"></i>
                        </div>
                        <h3>Location</h3>
                        <ul>
                            <li>115 London Rd, South Benfleet, Benfleet SS7 5UH, United Kingdom</li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-call"></i>
                        </div>

                        <h3>Phone</h3>

                        <ul>
                            <li><a href="tel:+44 7440020888"> +44 7440020888</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-email"></i>
                        </div>
                        
                        <h3>Email</h3>

                        <ul>
                            <li><a href="mailto:hello@lufz.com">support@vrish.co.uk</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <form  [formGroup]="contactForm">
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="name" id="name" class="form-control"
                         required data-error="Please enter your name" 
                         formControlName="name" placeholder="Your Full Name">
                         <label *ngIf="contactForm.controls['name'].invalid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched)" class="alert">
                            <div *ngIf="contactForm.controls['name'].errors.required" style="color:red">
                                Full name is required
                            </div>
                        </label>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" name="email" id="email" class="form-control"
                         required data-error="Please enter your email" 
                         formControlName="email" placeholder="Your Email">
                         <label *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched)" class="alert">
                            <div *ngIf="contactForm.controls['email'].errors.required && ('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')" style="color:red">
                                Email is required
                            </div>
                        </label>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="phone_number" id="phone_number" 
                        required data-error="Please enter your number" class="form-control" 
                        formControlName="mobile" placeholder="Your Phone">
                        <label *ngIf="contactForm.controls['mobile'].invalid && (contactForm.controls['mobile'].dirty || contactForm.controls['mobile'].touched)" class="alert">
                            <div *ngIf="contactForm.controls['mobile'].errors.required" style="color:red">
                                Mobile is required
                            </div>
                        </label>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="msg_subject" id="msg_subject"
                         class="form-control" required data-error="Please enter your subject"
                         formControlName="subject" placeholder="Subject">
                         <label *ngIf="contactForm.controls['subject'].invalid && (contactForm.controls['subject'].dirty || contactForm.controls['subject'].touched)" class="alert">
                            <div *ngIf="contactForm.controls['subject'].errors.required" style="color:red">
                                Subject is required
                            </div>
                        </label>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" 
                        cols="30" rows="8" required data-error="Write your message" 
                        formControlName="description" placeholder="Case Description"></textarea>
                        <label *ngIf="contactForm.controls['description'].invalid && (contactForm.controls['description'].dirty || contactForm.controls['description'].touched)" class="alert">
                            <div *ngIf="contactForm.controls['description'].errors.required" style="color:red">
                                Message is required
                            </div>
                        </label>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button type="submit" class="contact-btn" (click)="contactButton()" >Submit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.0341049460403!2d0.5508984000000001!3d51.567608299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8c5c1788be925%3A0xf915738803d92a35!2sVrish%20Authentic%20Indian%20finedine!5e0!3m2!1sen!2sin!4v1687416333123!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>