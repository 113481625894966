import { Component, OnInit } from '@angular/core';
import * as menusData from '../../../../assets/json/menu-details.json';

interface menu {
  category_title: String;
  category_img_url: String;
  menu_title:String;
  menu_price:Number;
  menu_img_url: String;
}

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss']
})
export class CaseStudyComponent implements OnInit {
  menusData : any = menusData ;
  selectedMenuData: any;
  collectionSize = 0;
  menusFilterData: any;
  activePage:number = 1;
  pageSize = 12;


  constructor() { 
  }

  ngOnInit(): void {

    fetch('assets/json/menu-details.json').then(res => res.json())
    .then(jsonData => {
      
      let arr = [];
      jsonData.forEach((item) => {
          arr = [...arr, ...item.menus];
      });
      this.selectedMenuData = {
        category_title: 'All Categories',
        menus: arr
      }
      jsonData.unshift(this.selectedMenuData);
      this.menusData = jsonData;
      console.log(this.menusData ,'menusData');
      this.displayActivePage(1);
    });
  }
  displayActivePage(activePageNumber:number){
    console.log(activePageNumber ,'number');
    this.menusFilterData = [];
    let arr = this.selectedMenuData.menus;
    let nextNumber: any;
    let currentNumber = (activePageNumber-1) * this.pageSize;
    nextNumber = (activePageNumber) * this.pageSize;
    for (let i=currentNumber;i < nextNumber;i++){
      if(this.menusFilterData.length < this.pageSize && i < arr.length){
        this.menusFilterData.push(arr[i]);
      }
    }
    this.activePage = activePageNumber;
  }
  menusDetailsPage(data){
    localStorage.setItem('menusDetails', JSON.stringify(data));
  }
  selectTabValue(category) {
        this.selectedMenuData = category;  
        this.displayActivePage(1);
        const element = document.getElementById("focusBtn");
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}
